interface AvatarProps {
  title: string;
  titleSize?: string;
  size?: "small" | "medium" | "large";
  dot?: boolean;
  color?:
    | "gray"
    | "blue"
    | "red"
    | "green"
    | "yellow"
    | "pink"
    | "female"
    | "male";
  circle?: number; // Valor de 0 a 100
  tag?: string;
}

export default function Avatar({
  size = "medium",
  title = "",
  titleSize = "14px",
  dot = false,
  color = "blue", // Por defecto, se usa "blue"
  circle = 0, // Por defecto, 0 significa que no hay relleno en el círculo
  tag = "",
}: Readonly<AvatarProps>) {
  const colorConfig = {
    blue: {
      bgColor: "bg-blue-100",
      fillColor: "#3B82F6",
    },
    red: {
      bgColor: "bg-red-100",
      fillColor: "#EF4444",
    },
    green: {
      bgColor: "bg-green-100",
      fillColor: "#10B981",
    },
    yellow: {
      bgColor: "bg-yellow-100",
      fillColor: "#F59E0B",
    },
    pink: {
      bgColor: "bg-pink-100",
      fillColor: "#EC4899",
    },
    female: {
      bgColor: "bg-pink-100",
      fillColor: "#EC4899",
    },
    male: {
      bgColor: "bg-blue-100",
      fillColor: "#3B82F6",
    },
    gray: {
      bgColor: "bg-gray-100",
      fillColor: "#6B7280",
    },
  };

  const config = colorConfig[color];

  const sizeClasses = {
    small: "w-9 h-9",
    medium: "w-10 h-10",
    large: "w-12 h-12",
  };

  const radius = size === "small" ? 16 : 18; // Radio del círculo
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (circle / 100) * circumference;

  const svgSize = size === "large" ? 48 : size === "medium" ? 40 : 36;

  return (
    <div className="relative flex items-center">
      <span
        className={`rounded-full ${sizeClasses[size]} ${config.bgColor} flex items-center justify-center`}
        style={{ fontSize: titleSize }}
      >
        {title}
      </span>
      {tag && (
        <span className="absolute z-10 -bottom-1 -right-1 bg-red-500 text-white text-[8px] px-1 rounded">
          {tag}
        </span>
      )}
      {circle > 0 && (
        <svg
          className="absolute inset-0"
          width={svgSize}
          height={svgSize}
          viewBox={`0 0 ${svgSize} ${svgSize}`}
        >
          <circle
            stroke="currentColor"
            strokeWidth="2"
            fill="transparent"
            r={radius}
            cx="50%"
            cy="50%"
            className="text-gray-100"
          />
          <circle
            stroke="currentColor"
            strokeWidth="2"
            strokeDasharray={circumference}
            strokeDashoffset={strokeDashoffset}
            strokeLinecap="round"
            fill="transparent"
            r={radius}
            cx="50%"
            cy="50%"
            style={{
              stroke: config.fillColor,
              transformOrigin: "50% 50%",
              transform: "rotate(-290deg)", // Rotar en sentido antihorario para iniciar desde la derecha
            }}
          />
        </svg>
      )}
      {dot && (
        <div>
          <span className="absolute bottom-[-1.5px] right-[0.5px] inline-flex rounded-full h-[12px] w-[12px] bg-white"></span>
          <span className="absolute bottom-[0px] right-0.5 flex h-[8px] w-[8px] flex-shrink-0">
            <span className="absolute inline-flex h-full w-full rounded-full bg-white"></span>
            <span
              className="animate-ping absolute inline-flex h-full w-full rounded-full opacity-75"
              style={{ backgroundColor: config.fillColor }}
            ></span>
            <span
              className="relative inline-flex rounded-full h-[8px] w-[8px]"
              style={{ backgroundColor: config.fillColor }}
            ></span>
          </span>
        </div>
      )}
    </div>
  );
}
